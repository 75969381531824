<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('li_step.' + 'service_' + search.service_id) }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
          <b-form  @submit.prevent="handleSubmit(searchData)" >
            <b-row>
              <b-col lg="4" sm="4">
                <ValidationProvider name="Division" vid="division_id">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="division_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('org_pro_division.division')}}
                        </template>
                        <b-form-select
                        plain
                        v-model="search.division_id"
                        :options="divisionList"
                        id="division_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="4" sm="4">
                <ValidationProvider name="District" vid="district_id">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="district_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{ $t('org_pro_district.district')}}
                      </template>
                      <b-form-select
                      plain
                      v-model="search.district_id"
                      :options="districtList"
                      id="district_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{ $t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                      {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="4" sm="4">
                <ValidationProvider name="Upazila" vid="upazila_id">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="upazila_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{ $t('org_pro_upazilla.upazilla')}}
                      </template>
                      <b-form-select
                      plain
                      v-model="search.upazilla_id"
                      :options="upazilaList"
                      id="upazila_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                      {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="4" md="4" sm="12">
                <ValidationProvider name="Type">
                  <b-form-group
                    label-for="type"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('globalTrans.type') }}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.type"
                      :options="dataType"
                      id="type"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="4" sm="4">
                <ValidationProvider name="Status" vid="status_id">
                    <b-form-group
                        label-for="status_id"
                        slot-scope="{ valid, errors }"
                    >
                        <template v-slot:label>
                        {{$t('globalTrans.status')}}
                        </template>
                        <b-form-select
                          v-model="search.status"
                          :options="statusList"
                          id="status_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{ $t('globalTrans.all') }}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="4" md="4" sm="12">
                <b-form-group
                  :label="$t('globalTrans.from_date')"
                  label-for="from_date"
                >
                  <b-form-input
                    id="datepicker"
                    v-model="search.from_date"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="4" sm="12">
                  <b-form-group
                    :label="$t('globalTrans.to_date')"
                    label-for="to_date"
                  >
                  <b-form-input
                    id="datepicker"
                    v-model="search.to_date"
                    ></b-form-input>
                  </b-form-group>
              </b-col>
              <b-col lg="4" sm="4" md="4">
                <ValidationProvider name="Application" vid="application_id" >
                  <b-form-group
                    label-for="application_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('globalTrans.application_id') }}
                    </template>
                    <b-form-input
                      plain
                      v-model="search.application_id"
                      id="application_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                    </b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="4" sm="4" md="4">
                <ValidationProvider name="Organization Name" vid="organization_name" >
                  <b-form-group
                    label-for="organization_name"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('grantDistribution.organization_name') }}
                    </template>
                    <b-form-input
                      plain
                      v-model="search.organization_name"
                      id="organization_name"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                    </b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="4" sm="4" md="4">
                <ValidationProvider name="Mobile" vid="mobile_no" >
                  <b-form-group
                    label-for="mobile_no"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('globalTrans.mobile') }}
                    </template>
                    <b-form-input
                      plain
                      v-model="search.mobile_no"
                      id="mobile_no"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                    </b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col class="col-sm-2">
                <br/>
                <b-button type="submit" variant="primary">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('li_step.' + 'service_' + search.service_id) }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new mr-2" @click="pdfExport">
              <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <div style="border: 2px solid;margin:10px;padding:10px">
                    <b-row>
                      <b-col>
                        <list-report-head :baseUrl="licenseRegistrationServiceBaseUrl" :url="'/configuration/report-heading/detail'" org-id="2">
                          {{ $t('li_step.' + 'service_' + search.service_id) }}
                        </list-report-head>
                      </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                          <b-row class="pl-3 pr-3 mb-3">
                            <b-col lg="4">
                              <strong>{{ $t('org_pro_division.division') }}: </strong>{{ searchHeader.division_id ? getColumnName($store.state.commonObj.divisionList, searchHeader.division_id) : $t('globalTrans.all') }}
                            </b-col>
                            <b-col lg="4">
                              <strong>{{ $t('org_pro_district.district') }}: </strong>{{ searchHeader.district_id ? getColumnName($store.state.commonObj.districtList, searchHeader.district_id) : $t('globalTrans.all') }}
                            </b-col>
                            <b-col lg="4">
                              <strong>{{ $t('org_pro_upazilla.upazilla') }}: </strong>{{ searchHeader.upazilla_id ? getColumnName($store.state.commonObj.upazilaList, searchHeader.upazilla_id) : $t('globalTrans.all') }}
                            </b-col>
                            <b-col lg="4">
                              <slot v-if="searchHeader.type">
                                  <strong> {{ $t('globalTrans.type') }}: </strong> <slot v-if="searchHeader.type === 1">{{ $t('globalTrans.new') }}</slot><slot v-else>{{ $t('globalTrans.renew') }}</slot>
                              </slot>
                              <slot v-else>
                                  <strong> {{ $t('globalTrans.type') }}: </strong> {{ $t('globalTrans.all') }}
                              </slot>
                            </b-col>
                            <b-col lg="4">
                                <strong>{{ $t('globalTrans.status') }}: </strong>{{ searchHeader.status ? getColumnName(statusList, parseInt(searchHeader.status)) : $t('globalTrans.all') }}
                            </b-col>
                            <b-col lg="4">
                              <slot v-if="searchHeader.from_date">
                                  <strong>{{ $t('globalTrans.from_date') }}: </strong> {{ searchHeader.from_date | dateFormat }}
                              </slot>
                              <slot v-else>
                                  <strong>{{ $t('globalTrans.from_date') }}: </strong>{{ $t('globalTrans.all') }}
                              </slot>
                            </b-col>
                            <b-col lg="4">
                              <slot v-if="searchHeader.to_date">
                                  <strong>{{ $t('globalTrans.to_date') }}: </strong>{{ searchHeader.to_date | dateFormat }}
                              </slot>
                              <slot v-else>
                                  <strong>{{ $t('globalTrans.to_date') }}: </strong>{{ $t('globalTrans.all') }}
                              </slot>
                            </b-col>
                            <b-col lg="4">
                              <slot v-if="searchHeader.application_id">
                                  <strong>{{ $t('globalTrans.application_id') }}: </strong>{{ searchHeader.application_id }}
                              </slot>
                              <slot v-else>
                                  <strong>{{ $t('globalTrans.application_id') }}: </strong>{{ $t('globalTrans.all') }}
                              </slot>
                            </b-col>
                            <b-col md="4" class="text-left">
                              {{ $t('grantDistribution.organization_name') }}: <strong>{{ searchHeader.organization_name ? searchHeader.organization_name : $t('globalTrans.all') }}</strong>
                            </b-col>
                            <b-col md="4" class="text-left">
                              {{ $t('globalTrans.mobile') }}: <strong>{{ searchHeader.mobile_no ? searchHeader.mobile_no : $t('globalTrans.all') }}</strong>
                            </b-col>
                        </b-row>
                        </b-col>
                    </b-row>
                    <b-table :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                      <template v-slot:cell(index)="data">
                        {{ $n(data.index + pagination.slOffset) }}
                      </template>
                      <template v-slot:cell(status)="data">
                        {{ getStepName(data.item.current_step) + ' ' + ServiceLastStepCheck(data.item.service_id, data.item.current_step, data.item.status, data.item.generate_id, data.item.doc_verified, data.item.type) }}
                      </template>
                      <template v-slot:cell(district_8192)="data">
                        {{ getDistrict(data.item.district_8192) }}
                      </template>
                      <template v-slot:cell(upazilla_4142)="data">
                        {{ getUpazila(data.item.upazilla_4142) }}
                      </template>
                      <template v-slot:cell(issue_date)="data">
                        <span v-if="(data.item.issue_date)">{{ data.item.issue_date | dateFormat }}</span>
                      </template>
                      <template v-slot:cell(expire_date)="data">
                        <span v-if="(data.item.expire_date)">{{ data.item.expire_date | dateFormat }}</span>
                      </template>
                      <template v-slot:cell(type)="data">
                        <span class="badge pay-status badge-success w-100 text-white font-weight-bold" v-if="data.item.type === 2">
                          {{ $t('globalTrans.renew') }}
                        </span>
                        <span class="badge pay-status badge-primary w-100 text-white font-weight-bold" v-if="data.item.type === 1">
                          {{ $t('globalTrans.new') }}
                        </span>
                      </template>
                      <template v-slot:cell(action)="data">
                        <button style='margin-left: 4px' type="button" class="btn btn-info btn-sm"  size="sm" @click="detailsData(data.item, data.item.status, 2)"><i :class="getBtn(2).icon"></i></button>
                        <!-- <router-link :to="{ name: getBtn(2).url, params: { stepChange }}" :title="getBtn(2).text" :class="`btn btn-sm`+ getBtn(2).color" size="sm"><span @click="SetStep(data.item, 1)"><i :class="getBtn(2).icon"></i></span></router-link> -->
                        <!-- License View -->
                        <slot v-if="data.item.service_id === 17">
                          <button v-if="data.item.generate_id !== null && data.item.status === 1"  style='margin-left: 4px' type="button" class="btn btn-success btn-sm"  size="sm" @click="detailsData(data.item, 1, 5)"><i :class="getBtn(5).icon"></i></button>
                        </slot>
                        <slot v-else>
                          <button v-if="data.item.generate_id !== null"  style='margin-left: 4px' type="button" class="btn btn-success btn-sm"  size="sm" @click="detailsData(data.item, 1, 5)"><i :class="getBtn(5).icon"></i></button>
                        </slot>
                      </template>
                    </b-table>
                    <b-pagination
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total-rows="pagination.totalRows"
                      @input="searchData"
                      />
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { daeImportReport, challanToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ListReportHead from '@/components/custom/ListReportHeadLrcpnOn.vue'
import flatpickr from 'flatpickr'
import ExportPdf from './export_pdf'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    ValidationObserver,
    ListReportHead,
    ValidationProvider
  },
  data () {
    return {
      search: {
        service_id: this.$route.meta.service_id,
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        type: 0,
        status: 0,
        application_id: '',
        organization_name: '',
        mobile_no: '',
        from_date: '',
        to_date: ''
      },
      searchHeader: {
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        type: 0,
        from_date: '',
        organization_name: '',
        mobile_no: '',
        to_date: ''
      },
      stepChange: {
        application_id: [],
        step_id: 0,
        service_id: 0,
        org_id: 0,
        status: 1
      },
      licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl,
      districtList: [],
      upazilaList: [],
      serviceList: []
    }
  },
  computed: {
    statusList () {
      return [
        { value: 7, text: this.$i18n.locale === 'en' ? 'Review' : 'পুনঃমূল্যায়ন', text_en: 'Review', text_bn: 'পুনঃমূল্যায়ন' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Processing' : 'প্রক্রিয়াধীন', text_en: 'Processing', text_bn: 'প্রক্রিয়াধীন' },
        { value: 1, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত', text_en: 'Approved', text_bn: 'অনুমোদিত' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Rejected' : 'প্রত্যাখ্যাত', text_en: 'Rejected', text_bn: 'প্রত্যাখ্যাত' }
      ]
    },
    dataType () {
      const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'New' : 'নতুন', text_en: 'New', text_bn: 'নতুন' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Reneiw' : 'রিনিউ', text_en: 'Reneiw', text_bn: 'রিনিউ' }
      ]
      return userList
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('globalTrans.entry') : this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('li_step.bin_no'), class: 'text-left' },
        { label: this.$t('externalLrcpn.app_id'), class: 'text-left' },
        { label: this.$t('grantDistribution.organization_name'), class: 'text-left' },
        // { label: this.$t('org_pro_district.district'), class: 'text-left' },
        // { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-left' },
        { label: this.$t('externalLrcpn.issue_date'), class: 'text-left' },
        { label: this.$t('externalLrcpn.expired_date'), class: 'text-left' },
        { label: this.$t('externalLrcpn.type'), class: 'text-left' },
        { label: this.$t('globalTrans.status'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'file_no' },
          { key: 'application_id' },
          { key: 'organizati_9606' },
          // { key: 'district_8192' },
          // { key: 'upazilla_4142' },
          { key: 'issue_date' },
          { key: 'expire_date' },
          { key: 'type' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'file_no' },
          { key: 'application_id' },
          { key: 'organizati_2112' },
          // { key: 'district_8192' },
          // { key: 'upazilla_4142' },
          { key: 'issue_date' },
          { key: 'expire_date' },
          { key: 'type' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    '$route.meta.service_id': function (newVal) {
      this.search.service_id = this.$route.meta.service_id
      this.loadData()
    },
    'search.org_id': function (newVal) {
      this.serviceList = this.getServiceList(newVal)
    },
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    State: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    core.index()
    flatpickr('#datepicker', {})
  },
  created () {
    this.loadData()
  },
  methods: {
    pdfExport () {
      const reportTitle = this.$t('li_step.' + 'service_' + this.search.service_id)
      ExportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail', 2, reportTitle, this.searchData, this)
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getDistrictList (divisionId = null) {
      let districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        districtList = districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    getDistrict (districtId = null) {
      const dataList = this.$store.state.commonObj.districtList.find(item => item.value === districtId)
      if (this.$i18n.locale === 'bn') {
        return dataList !== undefined ? dataList.text_bn : ''
      } else {
        return dataList !== undefined ? dataList.text_en : ''
      }
    },
    getUpazila (upazilaId = null) {
      const dataList = this.$store.state.commonObj.upazilaList.find(item => item.value === upazilaId)
      if (this.$i18n.locale === 'bn') {
        return dataList !== undefined ? dataList.text_bn : ''
      } else {
        return dataList !== undefined ? dataList.text_en : ''
      }
    },
    getApiType (apiType) {
      const tmp = this.apiList.find(item => item.value === apiType)
      if (typeof tmp !== 'undefined') {
        return tmp.text
      } else {
        return ''
      }
    },
    getServiceList (orgId) {
      return this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => item.status === 1 && item.org_id === orgId)
    },
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(licenseRegistrationServiceBaseUrl, challanToggleStatus, item)
    },
    ServiceLastStepCheck (serviceId, stepId, status, generateId, docVerify, type) {
      // const tmpStepList = this.$store.state.licenseRegistration.commonObj.serviceSteps.filter(item => item.service_id === parseInt(serviceId))
      // const tmpIndVStepList = tmpStepList.find(item => item.step_id === parseInt(stepId))
      // const order = tmpIndVStepList.order
      // const orderCheck = tmpStepList.find(item => item.order === order + 1)
      const actionData = this.actions(serviceId, stepId)
      if (!actionData) {
        return ''
      }
      const actions = JSON.parse(this.actions(serviceId, stepId).actions)
      if (type === 2 && status === 1) {
        return this.$t('globalTrans.completed')
      } else if (status === 0 && !docVerify) {
        return this.$t('globalTrans.processing')
      } else if (status === 2) {
        return this.$t('globalTrans.rejected')
      } else if (status === 7) {
        return this.$t('externalLrcpn.application_resubmit')
      } else if (status === 8) {
        return this.$t('externalLrcpn.transfered_to_nothi')
      } else if (actions.includes(4)) {
        if (status === 1) {
          return this.$t('globalTrans.completed')
        } else {
          return this.$t('globalTrans.processing')
        }
      } else if (actions.includes(6)) {
        if (docVerify === 1) {
          return this.$t('globalTrans.completed')
        } else {
          return this.$t('globalTrans.processing')
        }
      } else if (actions.includes(12)) {
        if (generateId) {
          return this.$t('globalTrans.completed')
        } else {
          return this.$t('globalTrans.processing')
        }
      } else if (actions.includes(14)) {
        if (generateId && status === 1) {
          return this.$t('globalTrans.completed')
        } else {
          return this.$t('globalTrans.processing')
        }
      } else {
        return this.$t('globalTrans.completed')
      }
    },
    actions (serviceId, stepId) {
      const actions = this.$store.state.licenseRegistration.commonObj.seviceStepWiseButtonList.find(item => item.step_id === stepId && item.service_id === serviceId)
      if (typeof actions === 'undefined') {
        return ''
      } else {
        return actions
      }
    },
    detailsData (item, status, btnId) {
      this.stepChange.application_id[0] = item.application_id
      this.stepChange.step_id = item.current_step
      this.stepChange.service_id = item.service_id
      this.stepChange.org_id = item.org_id
      this.stepChange.status = status
      const urlTo = this.getBtn(btnId).url
      this.$router.push({ name: urlTo, params: { stepChange: this.stepChange } })
    },
    SetStep (item, status) {
      this.stepChange.application_id[0] = item.application_id
      this.stepChange.step_id = item.current_step
      this.stepChange.service_id = item.service_id
      this.stepChange.org_id = item.org_id
      this.stepChange.status = status
    },
    getStepName (stId) {
      const step = this.$store.state.licenseRegistration.commonObj.stepNamesList.find(item => item.value === stId)
      return step.text
    },
    getBtn (value) {
      return this.$store.state.licenseRegistration.commonObj.dynamicButtonList.find(item => item.value === parseInt(value))
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(licenseRegistrationServiceBaseUrl, daeImportReport, params).then(response => {
        if (response.success) {
          this.searchHeader = Object.assign({}, this.search)
          this.$store.dispatch('setList', response.data.data)
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    }
  }
}
</script>
